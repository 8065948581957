/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { calculateBoosting } from './calculateBoosting'
import { checkIsAllListActive } from './editProduct'
import { getAccosColor } from './getAccosColor'
import { getActivationStatus } from './getActivationStatus'

const isValueExist = (v) => v !== null && v !== undefined
// On table init setup status of all products group
export const recalculateActiveStatus = (data) => {
  const isArray = Array.isArray(data)

  if (!isArray && !data.subRows.length) {
    return
  }

  if (isArray) {
    return checkIsAllListActive(data)
  }

  const active = recalculateActiveStatus(data.subRows)

  return active
}

// export const recalculateBoosting = (data) => {
//   const isArray = Array.isArray(data)

//   if (!isArray && !data.subRows.length) {
//     return
//   }

//   if (isArray) {
//     return calculateBoosting(data)
//   }

//   const boost = recalculateBoosting(data.subRows)

//   return boost
// }

export const rowGenerator = (data, acosMin, acosMax, rowPos, parentPos = -1, isSubRow = false) => {
  const {
    label,
    productLabel,
    nProducts: products,
    activationStatus,
    sales,
    cost,
    conversions,
    clicks,
    impressions,
    containedSubgroups,
    containedProducts,
    presentOnPlatform,
    advertisingNetProfitAbsolute,
    advertisingNetProfitProportion,
    ASINandSKU,
    totalNetProfitProportion,
  } = data

  const acos = isValueExist(data.ACoS?.value) ? Number(data.ACoS?.value) : '-'

  const tacos = isValueExist(data.TACoS?.value) ? Number(data.TACoS?.value) : '-'

  const profit = isValueExist(advertisingNetProfitProportion?.value)
    ? Number(advertisingNetProfitProportion.value)
    : '-'

  const profitAbsolute = data.totalNetProfitAbsolute

  const adProfitAbsolute = advertisingNetProfitAbsolute

  const returnRate = isValueExist(data.returnRate?.value) ? Number(data.returnRate.value) : '-'

  const acosColor = getAccosColor(acos, acosMin, acosMax)

  const items = []

  const pathToRow = parentPos !== -1 ? `${parentPos}.${rowPos}` : `${rowPos}`

  if (containedSubgroups && containedSubgroups?.length > 0) {
    containedSubgroups.map((item, index) => items.push(rowGenerator(item, acosMin, acosMax, index, pathToRow, true)))
  }

  if (containedProducts && containedProducts?.length > 0) {
    containedProducts.map((item, index) => items.push(rowGenerator(item, acosMin, acosMax, index, pathToRow, true)))
  }

  const active = items.length ? recalculateActiveStatus(items) : getActivationStatus(activationStatus)
  const boost = items.length ? calculateBoosting(items) : data.boost || 0

  // check is data is product (not a group!!)
  const minBoost = data.productID ? boost : !items.length ? boost : Math.min(...items.map((item) => item.minBoost))
  const maxBoost = data.productID ? boost : !items.length ? boost : Math.max(...items.map((item) => item.maxBoost))

  const rowData = {
    id: data.productID,
    productID: data.productID,
    channelID: data.channelID,
    name: label || productLabel,
    ean: data.EAN,
    products,
    active,
    acos,
    tacos,
    profit,
    acosColor,
    sales,
    cost,
    conversions,
    clicks,
    impressions,
    path: pathToRow,
    presentInChannels_count: isValueExist(data?.presentInChannels_count) ? data.presentInChannels_count : '-',
    presentInChannels_details: data.presentInChannels_details,
    isSubRow,
    returnRate,
    profitAbsolute,
    adProfitAbsolute,
    totalSales: data.totalSales,
    subRows: items,
    boost,
    // This fields need to compare changes in active or boost fields.
    // At first - set to default
    activeEdited: active,
    boostEdited: boost,
    minBoost,
    maxBoost,
    presentOnPlatform,
    performanceClass: data?.performanceClass,
    asin: isValueExist(ASINandSKU?.ASIN) ? ASINandSKU.ASIN : '-',
    customerID: isValueExist(data?.customerID) ? data?.customerID : '-',
    totalNetProportion: isValueExist(totalNetProfitProportion?.value) ? Number(totalNetProfitProportion?.value) : '-',
  }

  return rowData
}
