/* eslint-disable consistent-return */
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns'
import moment from 'moment'

export const dateRanges = {
  today: {
    startDate: startOfDay(addDays(new Date(), 0)),
    endDate: endOfDay(addDays(new Date(), 0)),
  },
  yesterday: {
    startDate: startOfDay(addDays(new Date(), -1)),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
  lastSevenDays: {
    startDate: startOfDay(addDays(new Date(), -6)),
    endDate: endOfDay(addDays(new Date(), 0)),
  },
  pastMonth: {
    startDate: startOfMonth(addMonths(new Date(), -1)),
    endDate: endOfMonth(addMonths(new Date(), -1)),
  },
  last30Day: {
    startDate: startOfDay(addDays(new Date(), -30)),
    endDate: endOfDay(addDays(new Date(), 0)),
  },
  past3Month: {
    startDate: startOfMonth(addMonths(new Date(), -3)),
    endDate: endOfMonth(addMonths(new Date(), -1)),
  },
  past6Month: {
    startDate: startOfMonth(addMonths(new Date(), -6)),
    endDate: endOfMonth(addMonths(new Date(), -1)),
  },
  pastYear: {
    startDate: startOfYear(addYears(new Date(), -1)),
    endDate: endOfYear(addYears(new Date(), -1)),
  },
  past2Year: {
    startDate: startOfYear(addYears(new Date(), -2)),
    endDate: endOfYear(addYears(new Date(), -1)),
  },
}

export const getRangeLabel = (start, end) => {
  const { today, yesterday, lastSevenDays, pastMonth, last30Day, past3Month, past6Month, pastYear, past2Year } =
    dateRanges

  if (isSameDay(today.startDate, start) && isSameDay(today.endDate, end)) return 'Today'
  if (isSameDay(yesterday.startDate, start) && isSameDay(yesterday.endDate, end)) return 'Yesterday'
  if (isSameDay(lastSevenDays.startDate, start) && isSameDay(lastSevenDays.endDate, end)) return 'Last 7 days'
  if (isSameDay(pastMonth.startDate, start) && isSameDay(pastMonth.endDate, end)) return 'Past Month'
  if (isSameDay(last30Day.startDate, start) && isSameDay(last30Day.endDate, end)) return 'Last 30 Days'
  if (isSameDay(past3Month.startDate, start) && isSameDay(past3Month.endDate, end)) return 'Past 3 Months'
  if (isSameDay(past6Month.startDate, start) && isSameDay(past6Month.endDate, end)) return 'Past 6 Months'
  if (isSameDay(pastYear.startDate, start) && isSameDay(pastYear.endDate, end)) return 'Past year'
  if (isSameDay(past2Year.startDate, start) && isSameDay(past2Year.endDate, end)) return 'Past 2 year'

  return `${moment(start).format('yyyy.MM.DD')} - ${moment(end).format('yyyy.MM.DD')}`
}
