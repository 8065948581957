/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import moment from 'moment'
import { toast } from 'react-toastify'
import { MRT_ShowHideColumnsButton, MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { config } from '@fortawesome/fontawesome-svg-core'
import debounce from 'lodash.debounce'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Dialog, Popover } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { LoadingButton } from '@mui/lab'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'
import {
  NO_GROUP_FILTER_OPTION,
  productAnalysis,
  productAnalysisCSV,
  productAnalysisGroupings,
  productUpdateStatus,
  selectIsStatusUpdateLoading,
  selectIsproductAnalysisCSVDataLoading,
  selectProductAnalysisDataLoading,
  selectProductGroupingsOptions,
  selectProductGroupingsOptionsLoaded,
} from '../../store/slice/product'
import { userData } from '../../store/slice/user'
import { setPage } from '../../store/slice/page'
import { TABLE_VIEW_VARIANT } from './utils/TABLE_TYPE'
import {
  defaultColConfig,
  initStoredFilters,
  selectAcosRange,
  selectColConfig,
  selectFilters,
  selectIsFiltersChange,
  selectIsFiltersInited,
  selectNetProfitRange,
  selectTacosRange,
  setAcosRange,
  setColConfig,
  setDefaultFilters,
  setFilters,
  setNetProfitRange,
  setTacosRange,
  defaultFilters,
  initColConfig,
  selectTotalProfitRange,
  setTotalProfitRange,
} from '../../store/slice/cockpit'

import PageWrapper from '../../containers/PageWrapper'
import PageHeadline from '../../components/PageHeadline'
import CustomSelect from '../../components/Form/CustomSelect'
import CustomSlider from '../../components/Form/CustomSlider'
import CustomDataPicker from '../../components/Form/CustomDataPicker'
import usePrompt from '../../utils/hooks/preventPage'
import SearchBar from '../../components/Form/SearchBar'
import '@fortawesome/fontawesome-svg-core/styles.css'

import useStyles from './styles'
import { buildTable } from './utils/buildTable'
import MemoSwitch from './MemoSwitch'
import { getAccosColor, getNetProfitColor, getTaccosColor } from './utils/getAccosColor'
import KeywordButton from './KeywordButton'
import { fontAwesomeIcons } from './utils/tableIcons'
import HtmlTooltip from '../../components/HtmlTooltip'
import KeywordModal from '../../components/KeywordModal'
import { COCKPIT_TABLE_CHANGABLE_FIELDS, changeProductField } from './utils/editProduct'
import BoostSlider from '../../components/Form/CustomBoostSlider'
import ColumnVisibility from './ColumnVisibility'
import { getAccountID } from '../../utils/auth'
import { downloadURI } from '../../utils/download-file'

config.autoAddCss = false

const DEFAULT_VAL_NONE = 'filter-no-group'
const FILTER_SELECT_OPTIONS = [
  {
    value: 0,
    label: 'Products: All (incl. historical)',
  },
  {
    value: 1,
    label: 'Products: Available for purchase on platform',
  },
]

const isValueExist = (v) => v !== null && v !== undefined

const formatValue = (v, d = 2) =>
  isValueExist(v)
    ? new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: d,
        maximumFractionDigits: d,
      }).format(v)
    : '-'

const CockpitPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(userData)
  const filtersOptions = useSelector(selectProductGroupingsOptions)
  const isProductGroupingsOptionsLoaded = useSelector(selectProductGroupingsOptionsLoaded)
  const isProductsDataLoading = useSelector(selectProductAnalysisDataLoading)
  const isProductsCSVDataLoading = useSelector(selectIsproductAnalysisCSVDataLoading)
  const isProductsUpdateStatusLoading = useSelector(selectIsStatusUpdateLoading)

  const isFiltersInited = useSelector(selectIsFiltersInited)
  const storedFilters = useSelector(selectFilters)
  const [selectedFilters, setSelectedFilters] = useState({
    ...defaultFilters,
  })
  const selectedAcosRange = useSelector(selectAcosRange)
  const selectedTacosRange = useSelector(selectTacosRange)
  const selectedNetProfitRange = useSelector(selectNetProfitRange)
  const selectedTotalProfitRange = useSelector(selectTotalProfitRange)

  const selectIsFilterChanged = useSelector(selectIsFiltersChange)
  const storedColConfig = useSelector(selectColConfig)

  const [modifyTableProducts, setModifyTableProducts] = useState(null)

  const [isTableLoading, setIsTableLoading] = useState(true)
  const [tableType, setTableType] = useState()
  const [tableData, setTableData] = useState([])
  const [tableTotalHeaderData, setTableTotalHeaderData] = useState([])
  const [csvDataLoading, setCsvDataLoading] = useState(false)

  const memoTableData = useMemo(() => tableData, [tableData])

  const [isHeaderExpanded, setIsHeaderExpanded] = useState(false)

  const [keywordModal, setKeywordModal] = useState({
    isOpen: false,
    data: null,
  })

  const columnsToHideProp = useMemo(() => {
    const obj = {}

    if (storedColConfig[getAccountID()]) {
      storedColConfig[getAccountID()].forEach((item) => {
        obj[item.key] = item.value
      })
    } else {
      storedColConfig?.default?.forEach((item) => {
        obj[item.key] = item.value
      })
    }

    return obj
  }, [storedColConfig, user])

  const mainColTitle = useMemo(() => {
    let label = 'All Channels'

    if (!selectedFilters) return
    if (selectedFilters.groupBy === NO_GROUP_FILTER_OPTION.value) label = `All Products`
    if (selectedFilters.channel === 0 && selectedFilters.groupBy === NO_GROUP_FILTER_OPTION.value)
      label = 'All Products'
    if (selectedFilters.channel === -1 && selectedFilters.groupBy === NO_GROUP_FILTER_OPTION.value)
      label = 'All Channels'
    if (selectedFilters.groupBy !== NO_GROUP_FILTER_OPTION.value) label = `All ${selectedFilters.groupBy}`

    return label
  }, [selectedFilters])

  const prepeareFiltersToRequest = () => {
    const prepeared = {
      restrictToChannel: selectedFilters.channel,
      dateFrom: selectedFilters.dateFrom,
      dateTo: selectedFilters.dateTo,
      filterBy: selectedFilters.filterBy,
      showOnlyActive: selectedFilters.showOnlyActive,
    }

    if (selectedFilters.groupBy !== NO_GROUP_FILTER_OPTION.value) {
      prepeared.groupBy = selectedFilters.groupBy
    }

    return prepeared
  }

  const makeTableData = (res) => {
    try {
      const { tableData: tableDataBuilded, tableType: tableTypeBuilded } = buildTable(res, selectedAcosRange)
      if (tableDataBuilded?.row) {
        setTableData(tableDataBuilded.row)
      }
      if (tableDataBuilded?.totalData) {
        setTableTotalHeaderData(tableDataBuilded.totalData)
      }
      setTableType(tableTypeBuilded)
      setIsTableLoading(false)
    } catch (err) {
      setIsTableLoading(false)
    }
  }

  const setProductChanged = (path, newValue, fieldName) => {
    const { data, onlyChangedItems } = changeProductField({
      data: tableData,
      path,
      newValue,
      tableView: tableType,
      fieldName,
    })

    setTableData(data)
    // TODO: need to optimize the getting of changed elements so that you don't need to constantly extract and check all products, every time a single product is changed
    setModifyTableProducts(onlyChangedItems)
  }

  const handleBoostChange = debounce((path, newValue, fieldName) => {
    setProductChanged(path, newValue, fieldName)
  }, 300)

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: mainColTitle,
        Header: (
          <div className={`${classes.header_col} ${classes.text_left}`}>
            <span>{mainColTitle}</span>
            <ColumnVisibility
              columns={storedColConfig[getAccountID()] || defaultColConfig}
              onChange={(e) => {
                dispatch(
                  setColConfig({
                    ...storedColConfig,
                    [getAccountID()]: e,
                  })
                )
              }}
            />
          </div>
        ),
        enableSorting: false,
        minSize: 360,
        size: 360,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <div title={renderedCellValue} className={classes.name_td}>
              {renderedCellValue}
            </div>
            {row.original.productID && (
              <HtmlTooltip
                title={
                  !row.original.presentOnPlatform
                    ? 'Campaigns on platform have not been created yet, please try again tomorrow.'
                    : ''
                }
              >
                {selectedFilters.channel !== 0 && (
                  <div>
                    <KeywordButton
                      disabled={!row.original.presentOnPlatform}
                      clickHandler={() =>
                        setKeywordModal({
                          isOpen: true,
                          data: row.original,
                        })
                      }
                    />
                  </div>
                )}
              </HtmlTooltip>
            )}
          </>
        ),
      },
      {
        accessorKey: 'ean',
        header: 'EAN',
        Header: (
          <div className={`${classes.header_col} ${classes.text_left}`}>
            <span>EAN</span>
          </div>
        ),
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) =>
          (!row.depth && tableType !== TABLE_VIEW_VARIANT.SIMPLE) || row.subRows?.length ? '' : renderedCellValue,
        size: 131,
        minSize: 131,
      },
      {
        accessorKey: 'asin',
        header: 'ASIN',
        Header: (
          <div className={classes.header_col}>
            <span>ASIN</span>
            <span>{tableTotalHeaderData.asin}</span>
          </div>
        ),
        enableSorting: false,
        size: 131,
        minSize: 131,
      },
      {
        accessorKey: 'customerID',
        header: 'Custom ID',
        Header: (
          <div className={classes.header_col}>
            <span>Custom ID</span>
            <span>{tableTotalHeaderData.customerID}</span>
          </div>
        ),
        enableSorting: false,
        size: 131,
        minSize: 131,
      },
      {
        accessorKey: 'presentInChannels_count',
        header: 'Channels',
        Header: (
          <HtmlTooltip title="The number of channels that this product is sold through.">
            <div className={classes.header_col}>
              <span>Channels</span>
              <span>{tableTotalHeaderData.presentInChannels_count}</span>
            </div>
          </HtmlTooltip>
        ),
        title: undefined,
        enableSorting: false,
        size: 88,
        minSize: 88,
      },
      {
        accessorKey: 'products',
        header: 'Products',
        Header: (
          <HtmlTooltip title="The amount of advertised products in this grouping.">
            <div className={classes.header_col}>
              <span>Products</span>
              <span>{tableTotalHeaderData.products}</span>
            </div>
          </HtmlTooltip>
        ),
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => (row.depth === 0 ? formatValue(renderedCellValue, 0) : ''),
        size: 87,
        minSize: 87,
      },
      {
        accessorKey: 'activeEdited',
        enableSorting: true,
        header: 'Active',
        sortingFn: (rowA, rowB, columnId) => {
          const valueA = rowA.getValue(columnId) === -1 ? 0.5 : Boolean(rowA.getValue(columnId))
          const valueB = rowB.getValue(columnId) === -1 ? 0.5 : Boolean(rowB.getValue(columnId))
          if (valueA > valueB) return 1
          if (valueA < valueB) return -1
          return 0
        },
        Header: (
          <HtmlTooltip title="Indicates whether the advertisement for the product is active.">
            <div className={classes.header_col}>
              <span>Active</span>
            </div>
          </HtmlTooltip>
        ),
        Cell: ({ renderedCellValue, row }) =>
          row.activeEdited !== 'none' ? (
            <MemoSwitch
              active={renderedCellValue}
              onChangeHandler={(e) => {
                setProductChanged(row.id, e, COCKPIT_TABLE_CHANGABLE_FIELDS.activeEdited)
              }}
            />
          ) : (
            ''
          ),
        size: 90,
        minSize: 90,
      },
      {
        accessorKey: 'boostEdited',
        header: 'Boosting',
        Header: (
          <HtmlTooltip
            title={
              <div>
                <span>
                  Increase or decrease the advertising pressure for individual products. A rough guidance is that one
                  step represents a 2% change in Target-ACoS (up or down).
                </span>
                <br />
                <br />
                <span>
                  Explanation: The default Target ACoS you aim to achieve for a channel is set in the
                  &quot;Channel-settings&quot; and it is the same for all products. Based on that average, the Boosting
                  feature allows you to set a stronger or weaker advertising pressure for individual products.
                </span>
              </div>
            }
          >
            <div className={`${classes.header_col} ${classes.align_center}`}>
              <span>Boosting</span>
            </div>
          </HtmlTooltip>
        ),
        Cell: ({ renderedCellValue, row }) =>
          selectedFilters?.channel !== 0 && (
            <BoostSlider
              hideLabel={row.original.productID}
              value={renderedCellValue}
              onChange={(e) => handleBoostChange(row.id, e, COCKPIT_TABLE_CHANGABLE_FIELDS.boostEdited)}
              showRange={!row.original.productID}
              rangeMin={row.original.minBoost}
              rangeMax={row.original.maxBoost}
            />
          ),
        enableSorting: false,
        size: 161,
        minSize: 161,
      },
      {
        accessorKey: 'performanceClass',
        header: 'Performance class',
        Header: (
          <div className={`${classes.header_col} ${classes.text_left}`}>
            <span>Performance class</span>
          </div>
        ),
        enableSorting: true,
        Cell: ({ renderedCellValue, row }) => (
          <div className={`${classes.header_col} ${classes.text_left}`}>
            <span>{renderedCellValue}</span>
          </div>
        ),
        size: 180,
        minSize: 180,
      },
      {
        accessorKey: 'acos',
        header: 'ACoS (%)',
        Header: (
          <HtmlTooltip title="This Target ACoS is used as basis for DeepCliq calculations. Whether it is actually achieved depends on product competitiveness, market conditions and various product-level settings on DeepCliq.">
            <div className={`${classes.header_col} ${classes.text_center}`}>
              <span>ACoS (%)</span>
              <span>{tableTotalHeaderData.acos}</span>
            </div>
          </HtmlTooltip>
        ),
        size: 120,
        minSize: 120,
        Cell: ({ renderedCellValue, row }) => (
          <span
            className={`${classes.td_acos} ${classes.text_center} ${getAccosColor(
              +renderedCellValue,
              selectedAcosRange?.[0],
              selectedAcosRange?.[1]
            )}`}
          >
            {renderedCellValue}
          </span>
        ),
      },
      {
        accessorKey: 'tacos',
        header: 'TACoS (%)',
        Header: (
          <div className={`${classes.header_col} ${classes.text_center}`}>
            <span>TACoS (%)</span>
            <span>{tableTotalHeaderData.tacos}</span>
          </div>
        ),
        size: 125,
        minSize: 125,
        Cell: ({ renderedCellValue, row }) => (
          <span
            className={`${classes.td_acos} ${classes.text_center} ${getTaccosColor(
              +renderedCellValue,
              selectedTacosRange?.[0],
              selectedTacosRange?.[1]
            )}`}
          >
            {renderedCellValue}
          </span>
        ),
      },
      {
        accessorKey: 'profit',
        header: 'Ad profit (%)',
        Header: (
          <div className={`${classes.header_col} ${classes.text_center}`}>
            <span>Ad profit (%)</span>
            <span>{tableTotalHeaderData.profit}</span>
          </div>
        ),
        size: 130,
        minSize: 130,
        Cell: ({ renderedCellValue, row }) => (
          <span
            className={`${classes.td_acos} ${classes.text_center} ${getNetProfitColor(
              +renderedCellValue,
              selectedNetProfitRange?.[0],
              selectedNetProfitRange?.[1]
            )}`}
          >
            {renderedCellValue}
          </span>
        ),
      },
      {
        accessorKey: 'totalNetProportion',
        header: 'Total profit (%)',
        Header: (
          <div className={`${classes.header_col} ${classes.text_center}`}>
            <span>Total profit (%)</span>
            <span>{tableTotalHeaderData.totalNetProportion}</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <span
            className={`${classes.td_acos} ${classes.text_center} ${getNetProfitColor(
              +renderedCellValue,
              selectedTotalProfitRange?.[0],
              selectedTotalProfitRange?.[1]
            )}`}
          >
            {renderedCellValue}
          </span>
        ),
        size: 145,
        minSize: 145,
      },
      {
        accessorKey: 'totalSales',
        header: 'Total sales (€)',
        Header: (
          <div className={classes.header_col}>
            <span>Total sales (€)</span>
            <span>{tableTotalHeaderData.totalSales}</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => formatValue(renderedCellValue),
        size: 150,
        minSize: 150,
      },
      {
        accessorKey: 'sales',
        header: 'Ad sales (€)',
        Header: (
          <div className={classes.header_col}>
            <span>Ad sales (€)</span>
            <span>{tableTotalHeaderData.sales}</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => formatValue(renderedCellValue),
        size: 135,
        minSize: 135,
      },
      {
        accessorKey: 'adProfitAbsolute',
        header: 'Ad profit (€)',
        Header: (
          <div className={classes.header_col}>
            <span>Ad profit (€)</span>
            <span>{tableTotalHeaderData.adProfitAbsolute}</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => formatValue(renderedCellValue),
        size: 125,
        minSize: 125,
      },
      {
        accessorKey: 'profitAbsolute',
        header: 'Total profit (€)',
        Header: (
          <div className={classes.header_col}>
            <span>Total profit (€)</span>
            <span>{tableTotalHeaderData.profitAbsolute}</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => formatValue(renderedCellValue),
        size: 135,
        minSize: 135,
      },
      {
        accessorKey: 'cost',
        header: 'Ad spend (€)',
        Header: (
          <div className={classes.header_col}>
            <span>Ad spend (€)</span>
            <span>{tableTotalHeaderData.cost}</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => formatValue(renderedCellValue),
        size: 125,
        minSize: 125,
      },
      {
        accessorKey: 'returnRate',
        header: 'Return rate (%)',
        Header: (
          <HtmlTooltip
            title={`The average value is used when no product-specific data is available. Data that are imported automatically or uploaded in "Product Settings" will overrule this average.`}
          >
            <div className={classes.header_col}>
              <span>Return rate (%)</span>
              <span>{tableTotalHeaderData.returnRate}</span>
            </div>
          </HtmlTooltip>
        ),
        size: 160,
        minSize: 160,
      },
      {
        accessorKey: 'conversions',
        header: 'Conversions',
        Header: (
          <div className={classes.header_col}>
            <span>Conversions</span>
            <span>{tableTotalHeaderData.conversions}</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => formatValue(renderedCellValue, 0),
        size: 135,
        minSize: 135,
      },
      {
        accessorKey: 'clicks',
        header: 'Clicks',
        Header: (
          <div className={classes.header_col}>
            <span>Clicks</span>
            <span>{tableTotalHeaderData.clicks}</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => formatValue(renderedCellValue, 0),
        size: 125,
        minSize: 125,
      },

      {
        accessorKey: 'impressions',
        header: 'Impressions',
        Header: (
          <div className={classes.header_col}>
            <span>Impressions</span>
            <span>{tableTotalHeaderData.impressions}</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => formatValue(renderedCellValue, 0),
        size: 145,
        minSize: 145,
      },
    ],
    [
      memoTableData,
      selectedAcosRange,
      selectedTacosRange,
      selectedNetProfitRange,
      selectedTotalProfitRange,
      mainColTitle,
      selectedFilters,
      columnsToHideProp,
      user,
    ]
  )

  const table = useMaterialReactTable({
    columns,
    data: memoTableData,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableExpandAll: true,
    enableExpanding: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowVirtualization: true,
    muiTableBodyCellProps: ({ cell }) => {
      const { row } = cell
      const isTopRow = !row.original.productID
      const isExpanded = (row.getIsExpanded() && row.subRows?.length) || row.depth

      const isSubRow = row.depth

      return {
        sx: {
          fontSize: isTopRow ? '16px ' : '14px ',
          fontWeight: isTopRow ? '600 ' : '300 ',
          background: isExpanded ? '#F8F8F8;' : '#ffffff',
          '&:hover': {
            background: isExpanded ? 'blue' : 'rgb(242, 242, 242)',
          },
          '&:not(:first-child)': {
            padding: !isSubRow ? '12px' : '0px 12px',
          },
          '&:first-child': {
            paddingLeft: !isSubRow ? '12px' : row.depth === 1 ? '24px' : '12px',
            '& *': {
              opacity: row.depth > 1 ? 0 : row.depth <= 1 && !row.subRows.length ? 0.7 : 1,
            },
          },
          '&:nth-child(2)': {
            paddingLeft: isSubRow ? '30px' : '12px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '15px',
          },
          borderTop: isSubRow ? 0 : '1px solid rgba(196, 196, 196, 0.25)',
        },
      }
    },
    muiCircularProgressProps: {
      color: 'secondary',
      thickness: 5,
      size: 55,
    },
    muiSkeletonProps: {
      animation: 'pulse',
      height: 28,
    },
    enableColumnResizing: true,
    icons: fontAwesomeIcons,
    enableTopToolbar: false,
    filterFromLeafRows: true,
    initialState: {
      expanded: true,
      pagination: {
        pageSize: 10000000,
      },
    },
    muiPaginationProps: {
      showRowsPerPage: false,
      shape: 'rounded',
    },
    paginationDisplayMode: 'pages',
    state: {
      showLoadingOverlay: isTableLoading,
      columnVisibility: {
        ...columnsToHideProp,
        activeEdited: selectedFilters?.channel !== 0,
        boostEdited: selectedFilters?.channel !== 0,
        presentInChannels_count: selectedFilters?.channel === 0,
      },
    },
    paginateExpandedRows: false,
  })

  const requestTableData = (withLoading = true) => {
    if (withLoading) setIsTableLoading(true)

    dispatch(
      productAnalysis({
        id: user.accountID,
        params: {
          ...prepeareFiltersToRequest(),
        },
      })
    )
      .unwrap()
      .then((res) => {
        setModifyTableProducts(null)
        makeTableData(res)
      })
      // eslint-disable-next-line no-console
      .catch((err) => {
        toast.error(err, TOAST_DEFAULT_CONFIG)
        setIsTableLoading(false)
        // !FOR TEST
        // makeTableData(fakeNewResponse)
      })
  }

  const requestTableCSVData = () => {
    dispatch(
      productAnalysisCSV({
        id: user.accountID,
        params: {
          ...prepeareFiltersToRequest(),
        },
      })
    )
      .unwrap()
      .then((res) => {
        const blob = new Blob(
          [
            new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
            res,
          ],
          { type: 'application/csv;charset=utf-8' }
        )
        const u = window.URL.createObjectURL(blob)
        downloadURI(u, 'CSV.csv')
        window.URL.revokeObjectURL(u)
      })
      // eslint-disable-next-line no-console
      .catch((err) => {
        toast.error(err, TOAST_DEFAULT_CONFIG)
      })
  }

  const handleChangeFilter = (e, key) => {
    dispatch(setFilters({ ...selectedFilters, [key]: e, id: getAccountID() }))
  }

  const handleAcosChange = debounce((e) => {
    dispatch(setAcosRange({ value: e, id: getAccountID() }))
  }, 500)

  const handleTacosChange = debounce((e) => {
    dispatch(setTacosRange({ value: e, id: getAccountID() }))
  }, 500)

  const handleNetProfitChange = debounce((e) => {
    dispatch(setNetProfitRange({ value: e, id: getAccountID() }))
  }, 500)

  const handleTotalProfitChange = debounce((e) => {
    dispatch(setTotalProfitRange({ value: e, id: getAccountID() }))
  }, 500)

  const handleDataChange = (value) => {
    dispatch(
      setFilters({
        ...selectedFilters,
        dateFrom: moment(value[0]).format('yyyy-MM-DD'),
        dateTo: moment(value[1]).format('yyyy-MM-DD'),
      })
    )
  }

  const handleSave = () => {
    dispatch(
      productUpdateStatus({
        id: user.accountID,
        data: modifyTableProducts.map((item) => ({
          productID: item.productID,
          channelID: item.channelID,
          newActivationStatus: Boolean(item.activeEdited),
          newBoostStatus: item.boostEdited,
        })),
      })
    )
      .unwrap()
      .then(() => {
        // setModifyTableProducts(null)
        requestTableData(false)
        toast.success('Table data update success', TOAST_DEFAULT_CONFIG)
      })
      .catch((err) => {
        toast.error(err, TOAST_DEFAULT_CONFIG)
      })
  }

  usePrompt('Are you sure you want to leave?', modifyTableProducts)

  useEffect(() => {
    if (!modifyTableProducts) return () => {}

    const beforeUnloadCallback = (event) => {
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = 'Are you sure you want to leave?'
      return 'Are you sure you want to leave?'
    }

    window.addEventListener('beforeunload', beforeUnloadCallback)

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [modifyTableProducts])

  useEffect(() => {
    if (user.accountID) {
      if (!isProductGroupingsOptionsLoaded) {
        dispatch(productAnalysisGroupings(user.accountID))
        dispatch(initStoredFilters({ id: getAccountID() }))
      }
      dispatch(initColConfig({ id: getAccountID() }))
    }
  }, [user])

  useEffect(() => {
    if (storedFilters) {
      setSelectedFilters(storedFilters)
    }
  }, [storedFilters])

  useEffect(() => {
    if (!selectedFilters) return

    if (!isProductsDataLoading && user?.accountID && isProductGroupingsOptionsLoaded) {
      requestTableData()
    }
  }, [selectedFilters, isProductGroupingsOptionsLoaded])

  useEffect(() => {
    if (isFiltersInited && isProductGroupingsOptionsLoaded && !selectedFilters?.groupBy) {
      const defaultFind = filtersOptions.groupBy.find((item) => item.value === DEFAULT_VAL_NONE)
      dispatch(setFilters({ ...selectedFilters, groupBy: defaultFind }))
    }
  }, [isFiltersInited, isProductGroupingsOptionsLoaded])

  useEffect(() => {
    dispatch(setPage('cockpit'))
  }, [])

  return (
    <PageWrapper>
      <Box className={classes.page_wrapper}>
        <div className={classes.bgFix} />
        <PageHeadline isWhite>
          <Box className={`${classes.headline} ${!isHeaderExpanded ? classes.headline_collapsed : ''}`}>
            <Box className={classes.headline_row}>
              <Box className={classes.headline_slider}>
                <CustomSlider
                  label="ACoS"
                  step={0.1}
                  minRange={0}
                  maxRange={50}
                  minValue={selectedAcosRange?.[0]}
                  maxValue={selectedAcosRange?.[1]}
                  onChange={(e) => handleAcosChange(e)}
                />
              </Box>
              <Box className={classes.headline_slider}>
                <CustomSlider
                  label="TACoS"
                  step={0.1}
                  minRange={0}
                  maxRange={25}
                  minValue={selectedTacosRange?.[0]}
                  maxValue={selectedTacosRange?.[1]}
                  onChange={(e) => handleTacosChange(e)}
                />
              </Box>
              <Box className={classes.headline_slider}>
                <CustomSlider
                  label="Ad profit"
                  step={0.1}
                  minRange={-10}
                  maxRange={50}
                  minValue={selectedNetProfitRange?.[0]}
                  maxValue={selectedNetProfitRange?.[1]}
                  onChange={(e) => handleNetProfitChange(e)}
                />
              </Box>
              <Box className={classes.headline_slider}>
                <CustomSlider
                  label="Total profit"
                  step={0.1}
                  minRange={-10}
                  maxRange={50}
                  minValue={selectedTotalProfitRange?.[0]}
                  maxValue={selectedTotalProfitRange?.[1]}
                  onChange={(e) => handleTotalProfitChange(e)}
                />
              </Box>
            </Box>
            <Box className={classes.headline_row}>
              <Box className={classes.headline_filter}>
                <Box className={classes.headline_select}>
                  <CustomSelect
                    label="Channel:"
                    value={selectedFilters?.channel}
                    options={filtersOptions?.channels}
                    onChange={(e) => handleChangeFilter(e, 'channel')}
                  />
                </Box>
                <Box className={classes.headline_select}>
                  <CustomSelect
                    label="Group by:"
                    value={selectedFilters?.groupBy}
                    options={filtersOptions?.groupBy}
                    onChange={(e) => handleChangeFilter(e, 'groupBy')}
                  />
                </Box>
                <Box className={classes.headline_select}>
                  <CustomSelect
                    label="Filter by:"
                    value={selectedFilters?.showOnlyActive}
                    options={FILTER_SELECT_OPTIONS}
                    onChange={(e) => handleChangeFilter(e, 'showOnlyActive')}
                  />
                </Box>
                <Box className={classes.headline_date}>
                  <CustomDataPicker
                    onChange={handleDataChange}
                    initStartRange={selectedFilters?.dateFrom}
                    initEndRange={selectedFilters?.dateTo}
                  />
                </Box>
                <Box className={classes.headline_search}>
                  <SearchBar
                    value={selectedFilters?.filterBy}
                    onSubmit={(e) => handleChangeFilter(e, 'filterBy')}
                    placeholder="Enter your search term"
                  />
                </Box>
              </Box>
              <Box className={classes.headline_save}>
                <Button
                  variant="text"
                  onClick={() => {
                    setIsHeaderExpanded((prev) => !prev)
                  }}
                  className={`${classes.expand_button} ${isHeaderExpanded && classes.expanded}`}
                >
                  <div title={isHeaderExpanded ? 'Collapse' : 'Expand'}>
                    <KeyboardArrowDownIcon />
                  </div>
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(setDefaultFilters({ id: getAccountID() }))
                    setModifyTableProducts(null)
                  }}
                  disabled={!selectIsFilterChanged && !modifyTableProducts}
                >
                  Undo
                </Button>
                <Button variant="outlined" onClick={handleSave} disabled={!modifyTableProducts}>
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </PageHeadline>
        <Box
          className={`${classes.page_content} ${classes.root}`}
          sx={{
            height: isHeaderExpanded ? 'calc(100vh - 145px)' : 'calc(100vh - 85px)',
            transition: '0.3s',
          }}
        >
          <MaterialReactTable table={table} />
          <LoadingButton
            loading={isProductsCSVDataLoading}
            loadingPosition="start"
            startIcon={<DownloadIcon />}
            variant="outlined"
            className={classes.csv_btn}
            onClick={requestTableCSVData}
          >
            CSV
          </LoadingButton>
        </Box>
      </Box>
      <KeywordModal
        open={keywordModal.isOpen}
        productData={keywordModal.data}
        handleClose={() =>
          setKeywordModal({
            isOpen: false,
            data: null,
          })
        }
      />
    </PageWrapper>
  )
}
export default CockpitPage
