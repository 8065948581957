/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { memo, useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import Slider from '@mui/material/Slider'
import useStyles from './styles'
import CustomThumbComponent from '../CustomSliderThumb'

const ThumbWithValue = (props) => <CustomThumbComponent hideLabel {...props} />

const BoostSlider = ({
  value = 0,
  hideLabel,
  min = -3,
  max = 6,
  onChange,
  showRange = false,
  rangeMin = 0,
  rangeMax = 0,
}) => {
  const classes = useStyles()
  const [innerValue, setInnerValue] = useState(value)

  const handleChange = (e) => {
    setInnerValue(e)
    onChange(e)
  }

  const calcDiapasonStyle = () => {
    if (!rangeMin && !rangeMax) {
      return {
        left: '50%',
        right: '50%',
      }
    }

    const fullWidth = Math.abs(min) + Math.abs(max)
    const onePercent = 100 / fullWidth

    const left = 50 + rangeMin * onePercent
    const leftStyle = left === 0 ? '2%' : `${left}%`
    const right = 100 - (50 + rangeMax * onePercent)
    const rightStyle = right === 0 ? '2%' : `${right}%`

    return {
      left: leftStyle,
      right: rightStyle,
    }
  }

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  return (
    <Box className={`${classes.slider} ${!hideLabel && classes.opacity} ${!innerValue && hideLabel && classes.empty}`}>
      <Slider
        value={innerValue}
        min={min}
        max={max}
        slots={{ thumb: hideLabel ? ThumbWithValue : CustomThumbComponent }}
        onChange={(e) => handleChange(e.target.value)}
        aria-label="Default"
        valueLabelDisplay="off"
      />
      {showRange && <div className={classes.diapason} style={calcDiapasonStyle()} />}
    </Box>
  )
}

export default memo(BoostSlider)
